.App {
  text-align: center;
}

.App-header {
  background-color: #312c2d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .animated {
    animation: maozinha infinite 1s alternate-reverse;
  }
}

.content {
  background-color: #74051b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
}

@keyframes maozinha {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
